/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/moment@2.24.0/moment.min.js
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/jqueryui@1.11.1/jquery-ui.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/desandro-matches-selector@2.0.2/matches-selector.min.js
 * - /npm/toastr@2.1.4/build/toastr.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-mask-plugin@1.14.16/dist/jquery.mask.min.js
 * - /npm/jquery-lazy@1.7.10/jquery.lazy.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
